import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {environment} from 'environments/environment';
import {Subject} from 'rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {UserScoreService} from '@modules/achievement/core/services/user-score.service';
import {filter, tap} from 'rxjs/operators';
import {brand} from '../../../../../settings';

export interface UserProgress {
    lessons: any[];
    activitiesDone: number;
}

@Component({
    selector: 'app-my-city',
    templateUrl: './my-city.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyCityComponent implements OnInit {
    public myCityUrl: SafeResourceUrl = '';
    public cityParams = '00000000000';

    constructor(
        private _sanitizer: DomSanitizer,
        private userScoreService: UserScoreService
    ) {

    }
    ngOnInit(): void {
        this.loadingSubject.pipe(
            filter((isLoading) => !isLoading),
            tap(
                () => {
                    this.cityParams = Object.values( this.userProgress?.get('lessons') ).join('');
                    this.updateIframeUrl();
                }
            )
        ).subscribe();
    }

    public get loadingSubject(): Subject<boolean> {
        return this.userScoreService.loadingSubject;
    }

    public get userProgress(): TypedDataEntityInterface<UserProgress> {
        return this.userScoreService.userProgress[0];
    }

    private updateIframeUrl(): void {
        const url = new URL(environment.myCityUrl(brand));
        url.searchParams.set('cityParams', this.cityParams);
        this.myCityUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url.href);
    }


}