import {ChangeDetectorRef, Injectable} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {map, take, tap} from 'rxjs/operators';
import {combineLatest, of, switchMap} from 'rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {LeaderboardTableRow} from '@modules/achievement/core/components/leaderboard/leaderboard.component';
import {TypedDataCollectionInterface} from 'shared/models/octopus-connect/typed-data-collection.interface';
import {brand} from '../../../../../app/settings';
import {filterState} from 'fuse-core/components/root-filter/root-filter.component';
import {IndexService} from 'fuse-core/services/index.service';

const OPEN_BADGES_ENDPOINT = 'openbadges';

export interface Achievement {
    id: string;
    name: string;
    criteria: string;
    image: string;
    unLocked: boolean;
}

export interface OpenBadge {
    badge: string | null;
    criteria: string;
    description: string;
    id: string;
    image: URL;
    name: string;
    unLocked: boolean;
    awardDetails: { issueDate: string, username: string };
}

export interface UserPoints {
    uid: string;
    points: number;
    level: number;
    progress: number;
}

export interface UserProgress {
    lessons: any[];
    activitiesDone: number;
}


@Injectable({
    providedIn: 'root'
})
export class UserScoreService {

    public userPoints: TypedDataEntityInterface<UserPoints>[] = [];
    public userProgress: TypedDataEntityInterface<UserProgress>[] = [];
    public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public userAchievements: TypedDataEntityInterface<Achievement>[] = [];
    public userOpenBadges: TypedDataEntityInterface<OpenBadge>[] = [];
    public brand: string = brand;
    public selectedCollection = null;

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private indexService: IndexService,
    ) {
        const isPython = brand.toString() === 'amazon_python';
        // l'info doit être présente que pour python
        if (isPython) {
            this.communicationCenter
                .getRoom('root-filter')
                .getSubject('selected')
                .subscribe(selectedCollection => {
                    if (this.selectedCollection === null) {
                        this.selectedCollection = selectedCollection;
                        this.loadPointsAndProgressFromUserEvent(); // premier passage init
                    } else {
                        this.selectedCollection = selectedCollection;
                        this.loadPointsAndProgressFromUser(this.selectedCollection); // en passant this.selectedcollection déjà init update
                    }
                });
        } else {
            this.loadPointsAndProgressFromUserEvent();
        }
    }

    private loadPointsAndProgressFromUserEvent(): void {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (!!data) {
                    this.loadPointsAndProgressFromUser(this.selectedCollection);
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('showRewards')
            .pipe(
                tap(() => this.loadPointsAndProgressFromUser(this.selectedCollection))
            )
            .subscribe();

        this.communicationCenter
            .getRoom('achievement')
            .getSubject('refreshUserScore')
            .pipe(
                tap(() => this.loadPointsAndProgressFromUser(this.selectedCollection))
            )
            .subscribe();
    }

    /**
     * return data of leaderboard endpoint it's the list of better score made
     */
    public leaderboardData(): Observable<LeaderboardTableRow[]> {
        return this.octopusConnect.loadCollection('leaderboard').pipe(
            take(1),
            map((data: DataCollection) => (data.entities
                        .map(res => (
                            {studentName: res.get('label'), rank: +res.get('rank'), points: +res.get('score')}))
                )
            ));
    }

    private postLogout(): void {
        this.initializeUserPoints();
        this.initializeUserProgress();
    }

    private loadUserPoints(): Observable<TypedDataEntityInterface<UserPoints>[]> {
        return this.octopusConnect
            .loadCollection('user-points')
            .pipe(
                map(
                    (userPointsCollection) =>
                        userPointsCollection.entities as TypedDataEntityInterface<UserPoints>[]
                ),
                tap((entities: TypedDataEntityInterface<UserPoints>[]) => {
                    if (entities.length) {
                        this.userPoints = entities;
                    }
                })
            );
    }

    private loadUserProgress(): Observable<TypedDataEntityInterface<UserProgress>[]> {
        return this.octopusConnect
            .loadCollection('user-progress')
            .pipe(
                map(
                    (userProgressCollection) =>
                        userProgressCollection.entities as TypedDataEntityInterface<UserProgress>[]
                ),
                tap((entities: TypedDataEntityInterface<UserProgress>[]) => {
                    if (entities.length) {
                        this.userProgress = entities;
                        this.communicationCenter.getRoom('achievement').next('user-progress', entities);
                    }
                })
            );
    }

    private loadUserAchievements(selectedCollection: filterState): Observable<TypedDataEntityInterface<Achievement>[]> {
        let filter = {};
        let collectionObservable;

        if (selectedCollection !== null) {
            collectionObservable = this.indexService
                .pythonIdInRegardOfIndex(this.selectedCollection.toString())
                .pipe(
                    take(1),
                    map((concept) => {
                        filter['concepts'] = concept;
                        return filter;
                    })
                );
        } else {
            collectionObservable = of(filter);
        }

        return collectionObservable.pipe(
            switchMap((resolvedFilter) =>
                this.octopusConnect.loadCollection('achievements', resolvedFilter).pipe(
                    map(
                        (userAchievementCollection) =>
                            userAchievementCollection.entities as TypedDataEntityInterface<Achievement>[]
                    ),
                    tap((entities: TypedDataEntityInterface<Achievement>[]) => {
                        if (entities.length) {
                            this.userAchievements = entities;
                        }
                    })
                )
            )
        );
    }

    private loadUserOpenBadges(selectedCollection: filterState): Observable<TypedDataEntityInterface<OpenBadge>[]> {
        return (selectedCollection !== null && selectedCollection !== undefined
                ? this.indexService.pythonIdInRegardOfIndex(this.selectedCollection.toString()).pipe(
                    take(1),
                    map((concept) => ({concepts: concept}))
                )
                : of({})
        ).pipe(
            switchMap((filter) =>
                this.octopusConnect.loadCollection(OPEN_BADGES_ENDPOINT, filter).pipe(
                    map((userOpenBadgesCollection: TypedDataCollectionInterface<OpenBadge>) => userOpenBadgesCollection.entities),
                    tap((entities) => {
                        this.userOpenBadges = entities.length ? entities : [];
                    })
                )
            )
        );
    }

    private initializeUserPoints(): void {
        this.userPoints = [];
    }

    private initializeUserProgress(): void {
        this.userProgress = [];
    }

    private loadPointsAndProgressFromUser(selectedCollection: filterState): void {
        combineLatest([this.loadUserPoints(), this.loadUserProgress(), this.loadUserAchievements(selectedCollection), this.loadUserOpenBadges(selectedCollection)])
            .pipe(
                take(1),
            )
            .subscribe(() => {
                this.loadingSubject.next(false);
            });
    }
}
