export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'access-future-engineer': 'access-future-engineer',
    'accessorize': 'accessorize',
    'achievement': 'profil',
    'admin-role': 'admin-role',
    'allTypes': 'toustypes',
    'archive': 'archiver',
    'arrow_back': 'arrow_back',
    'arrow_diag': 'arrow_diag',
    'arrow-forward-rounded-500': 'arrow_forward_rounded_500',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right': 'arrow_right',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'assign': 'assignation',
    'assignation': 'assignation',
    'assignment_ind': 'assignment_ind',
    'avatar': 'avatar',
    'award': 'award',
    'back': 'keyboard_arrow_up',
    'bar_chart': 'bar_chart',
    'book': 'book',
    'brand': 'brand',
    'brand_menu': 'brand_menu',
    'brand_menu_large': 'brand_menu_large',
    'brand_square': 'brand_square',
    'check-circle-rounded_fill': 'check_circle_rounded_fill',
    'check-rounded': 'check_rounded',
    'chevron': 'chevron_down_circle',
    'chevron-down-circle': 'chevron_down_circle',
    'class_add': 'add',
    'close': 'cross',
    'contact': 'contact',
    'contact-us': 'contact-us',
    'conversion-path': 'conversion_path',
    'credits': 'coin',
    'cross': 'cross',
    'cross_outline': 'cross_outline',
    'cursor': 'cursor',
    'delete': 'trash',
    'diamond': 'diamond',
    'diversity-outlined-fill': 'diversity_3_outlined_fill',
    'document': 'document',
    'download-pdf': 'text_file',
    'drag_indicator': 'arrow_down_circle_outline',
    'duplicate': 'duplicate',
    'edit': 'edit',
    'editor-play': 'play_outline',
    'educ-role': 'educ-role',
    'educ-role-without-rec': 'educ-role-without-rec',
    'event-available': 'event_available',
    'event-upcoming': 'event_upcoming',
    'exit': 'exit',
    'feedback-dialog-by-steps__dissatisfied': 'sentiment_dissatisfied',
    'feedback-dialog-by-steps__satisfied': 'sentiment_satisfied',
    'feedback-dialog-by-steps__very-dissatisfied': 'sentiment_very_dissatisfied',
    'feedback-dialog-by-steps__very-satisfied': 'sentiment_very_satisfied',
    'file': 'corpus',
    'file_download': 'text_file',
    'fill-in-blanks': 'remplir_blancs',
    'group-outlined-fill': 'group_outlined_fill',
    'groups-form-add': 'check',
    'home': 'book',
    'icon-arrow-back': 'arrow_back',
    'mat-card-actions__icon-menu-trigger': 'keyboard_arrow_up',
    'idea': 'idea',
    'information': 'information',
    'institution_add': 'add',
    'join': 'join',
    'leaderboard': 'leaderboard',
    'lessons': 'transition',
    'link': 'url',
    'lock': 'padlock',
    'lock-rounded-fill': 'lock_rounded_fill',
    'mail': 'mail',
    'menu': 'menu_rounded',
    'next': 'next',
    'notifications': 'notifications_rounded',
    'notifications-unread': 'notifications_unread_rounded',
    'onboarding_header': 'onboarding_header',
    'package': 'package',
    'pdf': 'ressources',
    'person-outlined-fill': 'person_outlined_fill',
    'play': 'arrow_right',
    'play-as-icon': 'play_arrow_rounded_fill',
    'play-in-class': 'play',
    'play-preview': 'play_arrow_rounded_fill',
    'play-rounded-fill': 'play_arrow_rounded_fill',
    'plus': 'plus',
    'polygon-3': 'polygon_3',
    'register': 'input',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'sports-score-rounded': 'sports_score_rounded',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'step_success': 'step_done',
    'student_add': 'check_circle_outline',
    'timer': 'acute',
    'tips': 'tips',
    'tooltip-info': 'info',
    'trainer_add': 'add',
    'transition': 'transition',
    'trash': 'trash',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'user-menu': 'arrow_drop_down_700',
    'visibility': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_legalnotice': 'visibility',
    'visibility_privacy': 'privacy',
    'workgroup_add': 'add',
    'world': 'world',
    'xxx': 'dislike',
    'logo_CTZC_college':'logo_CTZC_college',
    'logo_CTZC_junior':'logo_CTZC_junior',
    'logo_CTZC_python':'logo_CTZC_python',
};