import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {IsNewUserService} from '@modules/achievement/core/is-new-user/is-new-user.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {ConfigService, INavlink} from '@modules/achievement/core/config.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject} from 'rxjs';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {Router} from '@angular/router';
import {filterState} from 'fuse-core/components/root-filter/root-filter.component';

@Component({
    templateUrl: './achievement.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent extends AutoUnsubscribeTakeUntilClass {
    public navLinks: INavlink;
    public showTabHeader = true; // show or not the select element my city my badges etc on tab
    constructor(private isNewUserService: IsNewUserService, private configService: ConfigService,
                private authenticationService: AuthenticationService,
                private communicationCenter: CommunicationCenterService,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef) {
        super();

        this.navLinks = this.configService.navLinks();


    }

    //ng on init
    public ngOnInit(): void {
        this.communicationCenter.getRoom('root-filter').getSubject('selected')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(selectedElement => {
                if (selectedElement === filterState.python) {
                    this.showTabHeader = true;
                } else {
                    this.showTabHeader = false;
                }
                this.changeDetectorRef.detectChanges();
            });
    }

    public get isNewUser(): boolean {
        return this.isNewUserService.isNewUser;
    }

    public navLinksToApply(): {
        path: string,
        keyTranslate: string
    }[] {
        if (this.authenticationService.isGAR()) {
            return this.navLinks.gar;
        } else {
            return this.navLinks.default;
        }
    }
}
