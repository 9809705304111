import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-reward-items',
    templateUrl: './reward-items.component.html',
})

export class RewardItemsComponent {
    @Input() rewards: IRewards[] = [];
}

export interface IRewards {
    id: string,
    url: string,
    title: string,
    label: string,
    lockedLabel: string,
    locked: boolean,
}