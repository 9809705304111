import {
  MyProfileComponent,

} from "./components/my-profile/my-profile.component";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AchievementComponent } from "./achievement.component";
import { AfterBeginningPopUpComponent } from "./onboarding/after-beginning-pop-up/after-beginning-pop-up.component";
import { BeginningPopUpComponent } from "@modules/achievement/core/onboarding/beginning-pop-up/beginning-pop-up.component";
import { CommonModule } from "@angular/common";
import { CommunicationCenterService } from "@modules/communication-center";
import { DataEntity } from "octopus-connect";
import { DynamicNavigationService } from "../../../navigation/dynamic-navigation.service";
import { FormsModule } from "@angular/forms";
import { IsNewUserGuard } from "@modules/achievement/core/is-new-user/is-new-user.guard";
import { IsUserLogged } from "../../../guards/is-user-logged.class";
import { LeaderboardComponent } from "./components/leaderboard/leaderboard.component";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MyCityComponent } from "./components/my-city/my-city.component";
import { SharedModule } from "shared/shared.module";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { MyRankingComponent } from './components/my-ranking/my-ranking.component';
import { OpenBadgesListComponent } from './components/open-badges-list/open-badges-list.component';
import {RewardItemsComponent} from '@modules/achievement/core/components/my-profile/reward-item/reward-items.component';
import {AchievementDialog} from '@modules/achievement/core/components/my-profile/achievement-dialog/achievement-dialog.component';
import { MyProfilePopupComponent } from './onboarding/my-profile-popup/my-profile-popup.component';
import {OpenBadgeDialog} from '@modules/achievement/core/components/my-profile/open-badge-dialog/open-badge-dialog.component';
import { LeaderboardPopUpComponent } from './onboarding/leaderboard-pop-up/leaderboard-pop-up.component';
import {FuseSharedModule} from 'fuse-core/shared.module';

const routes: Routes = [
  {
    path: "achievement",
    canActivate: [IsUserLogged],
    component: AchievementComponent,
    children: [
      {
        path: "",
        redirectTo: "onboarding",
        pathMatch: "full",
      },
      {
        path: "my-profile",
        component: MyProfileComponent,
      },
      {
        path: "my-city",
        component: MyCityComponent,
      },
      {
        path: "leaderboard",
        component: LeaderboardComponent,
      },
      {
        path: "onboarding",
        canActivate: [IsUserLogged, IsNewUserGuard],
        component: WelcomeComponent,
      },
    ],
  },
];

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        MatTableModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        RouterModule.forChild(routes),
        SharedModule,
        FormsModule,
        FuseSharedModule,
    ],
  declarations: [
    AchievementComponent,
    LeaderboardComponent,
    MyProfileComponent,
    OpenBadgeDialog,
    AchievementDialog,
    WelcomeComponent,
    BeginningPopUpComponent,
    AfterBeginningPopUpComponent,
    MyCityComponent,
    MyRankingComponent,
    OpenBadgesListComponent,
    MyProfilePopupComponent,
    LeaderboardPopUpComponent,
    RewardItemsComponent,
    ],
    providers: [],
})
export class AchievementModule {
  static forRoot(): ModuleWithProviders<AchievementModule> {
    return {
      ngModule: AchievementModule,
      providers: [],
    };
  }

  constructor(
    private dynamicNavigation: DynamicNavigationService,
    private communicationCenter: CommunicationCenterService
  ) {
    this.communicationCenter
      .getRoom("authentication")
      .getSubject("userData")
      .subscribe((data: DataEntity) => {
        if (data) {
          this.postAuthentication();
        } else {
          this.postLogout();
        }
      });
  }

  private postLogout(): void {
    this.dynamicNavigation.clearMenuItem("level0", "achievement");
  }

  private postAuthentication(): void {
    this.dynamicNavigation.refreshModuleMenu("level0", {
      id: "achievement",
      title: "achievement",
      translate: "generic.achievement",
      type: "item",
      icon: "achievement",
      url: "/achievement",
    });
  }
}