import { Component, EventEmitter, Input, Output } from "@angular/core";

import { OpenBadge } from "@modules/achievement/core/services/user-score.service";
import { TypedDataEntityInterface } from "shared/models/octopus-connect/typed-data-entity.interface";

@Component({
  selector: "app-open-badges-list",
  templateUrl: "./open-badges-list.component.html",
  styleUrls: ["./open-badges-list.component.scss"],
})
export class OpenBadgesListComponent {
  @Input() public badges: TypedDataEntityInterface<OpenBadge>[] = [];
  @Output() public badgeClick = new EventEmitter<
    TypedDataEntityInterface<OpenBadge>
  >();

  public onClick(badge: TypedDataEntityInterface<OpenBadge>): void {
    this.badgeClick.emit(badge);
  }
}